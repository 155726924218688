<template>
  <div v-if="isFetchProduct">
    <ApplicableAreaForm @saveDetail="updateDetail" :detail="detail" :update="true"/>
  </div>
</template>

<script>
  import ApplicableAreaForm from "../../components/applicable-area/ApplicableAreaForm";
  import { mapActions } from 'vuex';
  export default {
    name: 'EditApplicableArea',
    data: () => ({
       id:'',
      detail: {
         name: '',
       },
       isFetchProduct:false
     }),

    components: { ApplicableAreaForm },
    async created() {
      this.id = this.$route.params.id;
      await this.fetchDetail(this.id)
    },
    methods:{
      ...mapActions('product', ['fetchApplicableAreaDetail','updateApplicableAreaDetail']),
      fetchDetail(id){
        let self = this;
              this.fetchApplicableAreaDetail(id).then(res => {
                    self.detail = res.data.data;
                    self.isFetchProduct =true;
              });
      },
      updateDetail(){
        let self=this;
        this.updateApplicableAreaDetail({id:this.id,info:this.detail})
           .then(res => {
             self.$vs.notify({
               title: "Applicable Area Update",
               text: "Applicable Area updated successfully",
               color: "success",
             });
             self.$vs.loading.close();
           }).catch(err => {
            self.$vs.notify({
            title: err.data.title,
            text: err.data.message,
            color: 'danger'

          });
          self.$vs.loading.close();
        });

      }
    }
  };
</script>

<style scoped>

</style>
